














import { Component, Vue } from 'vue-property-decorator';
import AppPublicLayout from '@publicApp/shared/components/AppPublicLayout.vue';
import authGuards from '@/app/shared/config/auth-guards';
import { logoutUserAction } from '../../shared/actions';

@Component({
  beforeRouteEnter: authGuards,
  components: {
    AppPublicLayout,
  },
})
export default class Login extends Vue {
  public timer: any = null;

  public mounted() {
    this.timer = setTimeout(() => {
      this.redirectToNewApp();
    }, 5000);
  }

  public async redirectToNewApp() {
    await logoutUserAction();
    const platformUrl = process.env.VUE_APP_PLATFORM_URL;
    location.replace(platformUrl!);
  }

  public redirectNow(): void {
    clearTimeout(this.timer);
    this.redirectToNewApp();
  }
}
